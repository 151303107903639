.popup {
	position: fixed;
	height:100%;
	width:100%;
	top:0;
	left:0;
	display:none;
	text-align:center;
}   

.popup_bg {
	background:rgba(0, 0, 0, 0.8);
	position:absolute;
	z-index:1;
	height:100%;
	width:100%;
	cursor: pointer;
}


.popup_img {
	position: relative;
	margin:0 auto;
	z-index:2;
	max-height:94%;
	max-width:94%;
	top: 2%;
}


.goods-close{
	display: none;
	background: rgba(255, 255, 255, 0.9);
	position: fixed;
	top: 2%;
	right: 3%;
	z-index:2;	
	width: 34px;
	cursor: pointer;
	padding: 3px;
}
.goods-close__img {
	background: #333333;
	height: 34px; 
	width: 4px;
	position: relative;
	left: 15px;
	transform: rotate(45deg);
	z-index:3;
}
.goods-close__img:after {
	content: "";
	height: 4px; 
	width: 34px;
	background: #333333;
	position: absolute; 
	left: -15px; 
	top: 15px;
	z-index:3;
}


@media screen and (max-width: 768px) {
    .popup_img {
        position: relative;
        z-index:2;
        max-height:94%;
        max-width:100%;
		top: 24vh;
	}
	.goods-close{
		top: 25vh;
		right: 7px;
		padding: 0;
		background-color: rgba(255, 255, 255, 0.5);
	}
}