body{
    font-family: 'Open Sans', sans-serif;
    color: #333333
}
.wrapper{
    max-width: 1100px;
    padding: 0 15px;
    margin: 0 auto;
}



.header{
    background-color: #ff0000;
    padding: 20px 0;    
}
.header__container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header__logo{
    text-transform: uppercase;
    color: #ffffff;
    font-size: 20px;
    width: 45%;
    font-weight: bold;
}
.header-menu__tel{
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
}


.description{
    background: url(../img/bg1.jpg) center/cover no-repeat;
}
.description__opacity{
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    padding: 100px 0;
}
.description__content{
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    color: #ffffff; 
}
.description__ttile{
    max-width: 880px;
    font-size: 40px;
    text-transform: uppercase;
    line-height: 1.4;
    margin-bottom: 30px;
}
.description__subtittle{
    font-size: 40px;
    margin-bottom: 25px;
}
.description__text{
    font-size: 30px;
    margin-bottom: 25px;
}
.description__tel{
    color: #ffffff;
    font-size: 40px;
    font-weight: bold;
}


.feedback{
    padding: 40px 0;
}
.feedback__title{
    text-align: center;
    font-size: 22px;
    margin-bottom: 30px;
}   
.feedback-form{
    display: flex;;
    align-items: center;
    justify-content: center;
}
.feedback-form__input{
    display: flex;
    align-items: center;
    height: 40px;
    margin-right: 20px;
    font-size:20px;
    padding: 0 10px;
    border-radius: 10px;
    border: none; 
    border: 1px solid #333333;
    color: green;
    box-shadow: 0 14px 15px rgba(0,0,0,0.25), 0 8px 8px rgba(0,0,0,0.22);
}
.feedback-form__button{
    width: 150px;
    height: 40px;
    font-size:20px;
    text-transform: uppercase;
    background-color: #ff0000;
    border-radius: 10px;  
    border: none; 
    box-shadow: 0 14px 15px rgba(0,0,0,0.25), 0 8px 8px rgba(0,0,0,0.22);
    cursor: pointer;
    color: #ffffff;
}
.feedback-form__button:hover{
    background: #fb3030;
}
.feedback-form__button:active{
    background-color: #ff0000;
    box-shadow: none;
}
.feedback__message{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    color: green;
    height: 42px;
}


.goods{
    padding: 40px 0;
}
.goods__title{
    text-align: center;
    text-transform: uppercase;
    font-size: 30px;
    margin-bottom: 30px;
    color: #ff0000;
}
.goods-gallery{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.goods-gallery__img{
    width: 32%;
    margin-bottom: 20px;
    cursor: pointer;
}


.footer{
    background-color: #ff0000;
    padding: 20px 0;
}
.contacts__title{
    color: #ffffff;
    margin-bottom: 8px;
}
.contacts__list{
    display: flex;
}
.contacts__item{
    color: #ffffff;
    margin-bottom: 5px;
    margin-right: 20px;
}
.contacts__link{
    text-decoration: underline;
    color: #ffffff;
}


@media screen and (max-width: 768px) {
    .header{
        padding: 10px 0;    
    }
    .header__container{
        align-items: flex-start;
    }
    .header__logo, .header-menu__tel{
        font-size: 16px;
    }

    
    .description__opacity{
        padding: 40px 0;
    }
    .description__ttile{
        font-size: 22px;
        line-height: 1.4;
        margin-bottom: 20px;
    }
    .description__subtittle{
        font-size: 22px;
        margin-bottom: 20px;
    }
    .description__text{
        font-size: 22px;
        margin-bottom: 20px;
    }
    .description__tel{
        font-size: 22px;
    }


    .feedback{
        padding-bottom: 20px;
    }
    .feedback__title{
        font-size: 20px;
        line-height: 1.4;
        margin-bottom: 15px;
    }
    .feedback-form{
        flex-direction: column;
    }
    .feedback-form__input, .feedback-form__button{
        width: 100%;
        height: 50px;
        margin: 0 0 10px 0;
        padding: 0;
        text-align: center;
        font-size:24px;
    }


    .goods{
        padding-top: 20px;
    }
    .goods__title{
        font-size: 25px;
        line-height: 1.3;
        margin-bottom: 15px;
    }
    .goods-gallery__img{
        margin-bottom: 10px;
    }


    .footer{
        padding: 10px 0;
    }
    .contacts__list{
        flex-direction: column;
    }
}